@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


/* ====================================== */
/* Global Settings */
/* ====================================== */

html,
body,
* {
  font-family: var(--TTCommons), sans-serif;
  font-weight: 500;
  font-style: normal;
  scroll-behavior: smooth;
  @apply antialiased;
}

h1,
h1>span,
h2,
h2>span,
h3,
h3>span,
h4,
h4>span,
h5,
h5>span,
h6>span {
  font-family: var(--TTCommons), sans-serif;
  font-weight: bold;
  font-style: italic;
  @apply uppercase;
  @apply tracking-tight;
}

/* Headings */
.heading-1 {
  @apply text-4xl md:text-5xl lg:text-6xl xl:text-7xl;
}

.heading-2 {
  @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl;
}

.heading-3 {
  @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
}

.heading-4 {
  @apply text-xl md:text-2xl lg:text-3xl xl:text-4xl;
}

.heading-5 {
  @apply text-lg md:text-xl lg:text-2xl xl:text-3xl;
}

.heading-6 {
  @apply text-base md:text-lg lg:text-xl xl:text-2xl;
}

/* Intros */
.intro-1 {
  @apply text-lg md:text-xl;
}

.intro-2 {
  @apply text-base md:text-lg;
}

.intro-3 {
  @apply text-sm md:text-base;
}

/* Sections */
.section-base {
  @apply max-w-screen-2xl w-full;
}

.section-sm {
  @apply max-w-screen-md w-full;
}

.section-md {
  @apply max-w-screen-lg w-full;
}

.section-lg {
  @apply max-w-screen-xl w-full;
}

.section-xl {
  @apply max-w-screen-3xl w-full;
}



/* Paddings */
.global-padding {
  @apply p-4 md:p-6 lg:p-8;
}

.global-padding-x {
  @apply px-4 md:px-6 lg:px-8;
}

.global-padding-t {
  @apply px-4 pt-4 md:px-6 md:pt-6 lg:px-8 lg:pt-8;
}

.global-padding-md {
  @apply px-4 py-2 md:px-6 md:py-3 lg:px-8 lg:py-4;
}

.global-padding-lg {
  @apply px-4 py-8 md:px-6 md:py-12 lg:px-8 lg:py-16;
}

.global-padding-xl {
  @apply px-4 py-10 md:px-6 md:py-14 lg:px-8 lg:py-20;
}

/* Gaps */
.global-gap {
  @apply gap-4 md:gap-6 lg:gap-8;
}

.global-gap-xl {
  @apply gap-8 md:gap-10 lg:gap-12;
}

.global-gap-lg {
  @apply gap-6 md:gap-8 lg:gap-10;
}

.global-gap-sm {
  @apply gap-3 md:gap-6;
}

.global-gap-xs {
  @apply gap-2 md:gap-3;
}

/* Rounded */
.global-rounded {
  @apply rounded-3xl md:rounded-4xl transition-all;
}

.global-rounded-b {
  @apply rounded-b-3xl md:rounded-b-4xl transition-all;
}

.global-rounded-t {
  @apply rounded-t-3xl md:rounded-t-4xl transition-all;
}

.global-rounded-md {
  @apply rounded-2xl md:rounded-3xl transition-all;
}

.global-rounded-sm {
  @apply rounded-xl md:rounded-2xl transition-all;
}

/* .global-rounded-alt {
  @apply rounded-2xl md:rounded-4xl;
  transition: border-radius 0.3s ease;
} */

/* Buttons */
.btn-cta {
  @apply mx-auto bg-brand-blue-reg hover:bg-brand-yellow font-bold text-white hover:text-brand-blue-dark px-6 py-2 rounded-full transition-all duration-500 ease-in-out;
}


/* 
.colmena-p {
  @apply px-4 md:px-6 lg:px-8;
}

.colmena-p-alt {
  @apply px-6 md:px-8 lg:px-10;
}

.colmena-p-x-features {
  @apply px-2 md:px-4 lg:px-6;
} */

/* ====================================== */
/* Components */
/* ====================================== */

/* Scrolling text */
.ts {
  display: block;
  position: relative;
  padding: auto;
  margin: auto;
  width: 100%;
  overflow: visible;
}

.ts>.ts-word {
  display: inline;
}

.ts>.ts-word>.ts-char {
  display: inline;
}


.scrollToTopButton {
  @apply fixed bottom-3 right-3 bg-brand-blue-reg text-white p-2 z-50 items-center text-xs flex flex-col gap-2 rounded-full shadow-none hover:shadow-lg hover:bg-brand-blue-mid hover:text-white transition-all duration-300;
  cursor: pointer;
  opacity: 0;
}

.scrollToTopButton.visible {
  opacity: 1;
}

.form-field {
  @apply w-full bg-white rounded-xl text-base outline-none text-brand-blue-mid py-1 px-3 mb-1 leading-8 duration-300 ease-in-out border-2 focus:border-brand-blue-reg;
  /* focus: border-brand-blue-light focus:ring-1 focus:ring-brand-blue-light */
}

select.form-field {
  @apply appearance-none h-12;
}

textarea.form-field {
  @apply appearance-none h-24;
  resize: none;
}

.main-action-cta {
  @apply flex flex-col justify-center items-center h-12 max-h-12 bg-brand-blue-dark hover:bg-brand-yellow text-white hover:text-brand-blue-dark overflow-hidden w-96 text-center text-base mx-auto p-1 rounded-full transition-colors font-bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.main-action-cta.isLoading,
.main-action-cta.isLoading:hover {
  cursor: not-allowed;
  transform: scale(1);
}

.main-action-cta:hover {
  transform: scale(1.05);
}

.pill {
  @apply px-6 py-1.5 bg-brand-blue-reg text-white md:bg-brand-blue-dark rounded-2xl text-xs lg:text-xs uppercase font-bold tracking-wide;
}




.top-nav-link[aria-current="page"] {
  @apply text-brand-yellow;
}

.footer-bg {
  @apply bg-white text-brand-blue-mid;
}

.footer-links li a {
  @apply text-white transition;
}

.footer-links li a:hover {
  @apply text-white;
}

.footer-links li a.active,
.footer-links li a.active:hover {
  @apply text-brand-yellow;
}

.footer-social-media {
  @apply flex items-center space-x-3 mt-8 md:mt-0 lg:mt-8 justify-end md:justify-start lg:justify-end md:space-x-2;
}

@property --fill {
  syntax: '<percentage>';
  inherits: true;
  initial-value: 0%;
}

.footer-social-media li a {
  @apply block w-10 h-10 flex relative transition;
  z-index: 200;
}

.footer-social-media li a:hover {
  color: var(--brand-yellow);
  --fill: 100%;
}

.footer-social-media li a::before {
  position: absolute;
  z-index: -10;
  content: ' ';
  inset: -2px;
  border-radius: 50%;
  background: conic-gradient(var(--brand-yellow) 100%, transparent 100%);
}

.footer-social-media li a::before {
  position: absolute;
  z-index: -10;
  content: ' ';
  inset: -2px;
  border-radius: 50%;
  background: conic-gradient(var(--brand-yellow) var(--fill), transparent var(--fill));
  transition: --fill .5s ease-in-out;
}

.footer-social-media li a::after {
  position: absolute;
  z-index: -5;
  content: ' ';
  inset: 1px;
  border-radius: 50%;
  background: var(--brand-blue-mid);
}

.hero-box {
  @apply flex flex-col items-center justify-center;
}

.service-title {
  @apply text-2xl lg:text-3xl max-w-80 md:max-w-80 my-5;
}

.service-intro {
  @apply text-sm;
}

ul.tech-stack {
  @apply grid grid-cols-5 justify-center items-center gap-2 gap-y-3 md:gap-4;
}

ul.tech-stack li {
  @apply flex flex-col items-center relative text-xs;
}

ul.tech-stack li>div {
  @apply relative h-16 w-16 md:w-20 md:h-20 mb-2 bg-black/30 rounded-xl;
}

ul.tech-stack li>div>img {
  @apply h-full w-full p-4 md:p-5;
}


/* Websites */
.parallax-vertical {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  transform: perspective(600px) rotateY(-5deg) scale(1.05);
}

.scroller {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: block;
}

.scroller>div {
  width: 100%;
  position: absolute;
}

.heading-fadeT,
.heading-fadeB {
  @apply from-brand-blue-dark via-brand-blue-dark/30 to-brand-blue-dark/0 w-full h-20 absolute left-0 right-0;
  z-index: 100;
}

.heading-fadeT {
  @apply bg-gradient-to-b top-0 from-brand-blue-reg via-brand-blue-reg/30 to-brand-blue-reg/0;
}

.heading-fadeB {
  @apply bg-gradient-to-t bottom-0;
}

.custom-max-h {
  height: 100%;
  max-height: 950px !important;
}

@media (max-width: 768px) {
  .parallax-vertical {
    transform: perspective(0) rotateY(0deg) scale(1);
  }

  .heading-fadeT {
    @apply h-16 from-brand-blue-reg via-brand-blue-reg/30 to-brand-blue-reg/0;
  }

  .heading-fadeB {
    @apply h-12 from-brand-blue-dark via-brand-blue-dark/30 to-brand-blue-dark/0;
  }

  .custom-max-h {
    max-height: 250px;
  }
}

/* FAQ */
.faq-button {
  /* @apply flex items-center justify-between w-full border border-brand-blue-mid px-5 py-4 rounded-3xl text-brand-blue-mid text-left transition-all text-xl; */
  @apply flex items-center justify-between w-full px-5 py-4 rounded-3xl bg-brand-blue-mid text-white text-left transition-all;
}

.faq-button:hover,
.faq-button.open {
  @apply bg-brand-blue-reg text-white;
}

.faq-answer {
  @apply px-5 py-8 text-brand-blue-dark text-left md:text-lg;
}

/* Features */
.expandable-features {
  @apply flex flex-col gap-y-0;
}

.expandable-features .feature {
  @apply py-5 md:py-5 relative;
}

.expandable-features .feature h2 {
  @apply p-0 m-0
}

.expandable-features .feature h2 button {
  @apply p-0 m-0 text-lg lg:text-2xl text-left
}

.expandable-features .feature h2 button:hover {
  @apply text-orange-600
}

.expandable-features .feature>div.description {
  @apply text-base md:text-lg mt-8 mb-4 lg:max-w-[80%];
}

.expandable-features .feature>div.progressBar {
  @apply h-0.5 w-full bg-neutral-100 absolute bottom-0 left-0;
}

.expandable-features .feature>div.progressBar .progress {
  @apply h-full w-0 bg-orange-600;
  transition: width 0.3s ease;
}

.expandable-graphics {
  @apply relative bg-orange-100 w-full min-h-96 overflow-hidden rounded-2xl;
}

.expandable-graphics .graphic {
  @apply bg-orange-100 w-full h-full relative;
}

.expandable-graphics .graphic img {
  @apply w-full h-full object-cover;
}




.web-features {
  @apply bg-neutral-600 md:bg-transparent p-2 md:p-0 rounded-2xl relative grid grid-cols-5 gap-0 lg:grid-cols-4 mb-2 md:mb-10 min-h-fit;
}

@media (min-width: 768px) {
  .web-features {
    height: 100vh;
    max-height: 900px;
  }
}

.feature-description {
  @apply col-span-4 md:col-span-3 lg:col-span-2 flex flex-col justify-center md:justify-start text-white;
}

.feature-description .feature-sticky {
  @apply relative md:sticky flex justify-start md:top-[20%];
}

.feature-description .feature-sticky .feature-wrapper {
  @apply w-full md:pr-6 lg:pr-0 max-w-lg md:mb-10 xl:mx-auto;
}

.feature-description .feature-sticky .feature-wrapper>h3 {
  @apply w-full p-0 m-0;
}

.feature-description .feature-sticky .feature-wrapper>h3>button {
  @apply text-base md:text-2xl lg:text-3xl xl:text-4xl w-full text-left flex items-center gap-x-6 justify-between md:mb-4 lg:mb-6;
}

.feature-description .feature-sticky .feature-wrapper>.feature-line {
  @apply hidden max-w-xl md:block;
}

.feature-description .feature-sticky .feature-wrapper>.feature-line.open {
  @apply block max-w-xl mt-5;
}

.feature-description .feature-sticky .feature-wrapper>.feature-line>p {
  @apply text-sm md:text-base lg:text-lg leading-5 mb-2;
}

.feature-graphic {
  @apply col-span-1 md:col-span-2 flex flex-col justify-start md:justify-end order-first md:order-none;
}

.feature-graphic .feature-wrapper {
  @apply relative w-16 h-16 md:w-full md:h-72 lg:h-96;
}

/* web features */
.features-container {
  height: calc(100vh - 100px);
  min-height: 900px;
  max-height: 900px;
  @apply flex justify-center items-center sticky top-0 overflow-hidden;
}

.features-container .feature-card {
  @apply flex flex-col relative rounded-2xl w-full max-w-screen-lg md:max-w-screen-xl backdrop-blur overflow-hidden border border-neutral-200 border-dotted border-2;
  top: -25%;
  height: 600px;
  transform-origin: top;
}

.features-list-white span strong {
  @apply w-full block mb-2 heading-6 text-brand-blue-mid text-pretty;
}

.features-list-white span {
  @apply text-gray-500;
}


.benefits-list p strong {
  @apply w-full block mb-2 text-brand-blue-mid heading-6;
}

.benefits-list p {
  @apply text-gray-500;
}





/* Features Grid-2x */
.features-grid-2x-card {
  @apply grid grid-cols-5 w-full shrink-0 place-content-center rounded-2xl bg-white pl-0 pr-5 py-10;
}

.features-grid-2x-card h3 {
  @apply text-lg md:text-xl lg:text-2xl font-bold text-left text-brand-blue-mid;
}

.features-grid-2x-card h3 span {
  @apply font-bold text-brand-orange;
}

.features-grid-2x-card p {
  @apply text-sm lg:text-base text-brand-blue-dark;
}

.features-grid-2x-card .image-container {
  @apply w-full relative overflow-hidden flex;
}

.features-grid-2x-card .description-container {
  @apply col-span-4 flex flex-col gap-y-3;
}

.feature-title {
  @apply text-center;
}

.feature-title h3 {
  /* text-center text-4xl font-bold md: text-7xl */
  @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white;
}

.feature-title h3 span {
  @apply font-bold block text-brand-yellow;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}


.project-name span {
  @apply text-3xl sm:text-4xl md:text-4xl lg:text-5xl font-bold text-brand-blue-mid group-hover:text-brand-yellow;
}

.project-detail-name {
  @apply text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-brand-blue-mid;
}

.project-detail-name span {
  @apply text-4xl block sm:text-5xl sm:inline-block md:text-6xl lg:text-7xl font-bold text-brand-blue-mid;
}

/* .project-btn {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: none;
  width: 10rem;
  height: 10rem;
  padding: 0;
  margin: 1rem;
  font-family: inherit;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white
}

.project-btn:focus,
.project-btn--hover {
  outline: none;
  border-width: var(--button-stroke-width-hover);
  border-color: var(--button-stroke-hover);
  color: var(--button-text-hover);
  background: var(--button-bg-hover);
}

.project-btn__deco {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-width: var(--button-stroke-width);
  border-color: var(--button-stroke);
  border-style: solid;
  border-radius: var(--button-border-radius);
  background: var(--button-bg);
}

.project-btn__deco-filler {
  background: var(--button-filler);
  position: absolute;
  width: 150%;
  height: 200%;
  border-radius: 50%;
  top: -50%;
  left: -25%;
  transform: translate3d(0, 75%, 0);
}

.project-btn__text,
.project-btn__text-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
} */